* {
    background-color: #fbfaf0;
    color: #212121;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    text-align: center;
}
body {
    height: 100%;
}

/* App */
.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
}
footer {
    min-height: 100%;
    bottom: 0;
}

a:hover {
    background-color: #f2f2f2;
}

.header-full {
    position: sticky;
    top: 0px;
    z-index: 10;
}

.header-full-title {
    text-align: center;
    border-bottom: 1px solid;
    padding-top: 1%;
    padding-bottom: 1.8%;
}

.header-maintitle {
    font-size: 2.5vw;
}

.header-subtitle {
    font-size: 1.1vw;
}

.nav-bar {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid;
}

.middle-content {
    display: flex;
    justify-content: center;
}

.main-content {
    max-width: 700px;
    font-size: 17px;
    width: 100%;
}

/* App */

/* Home */
.home-main-title,
.main-title-article {
    font-size: 28px;
    margin: 20px 0px 2px;
}

.home-date {
    font-size: 13px;
    padding-top: 8px;
}

.home-sub-title,
.sub-title-article {
    font-size: 20px;
    margin-bottom: 15px;
}

.home-main-img {
    max-width: 350px;
    padding-bottom: 5px;
}

.writer {
    margin-top: 10px;
    display: block;
}

.home-full {
    height: 1000px;
}

.home-container {
    height: 900px;
    overflow: hidden;
}

.home-last-post {
    position: relative;
    height: 600px;
    overflow: hidden;
    padding-bottom: 20px;
}

.home-more-button {
    border-top: 2px solid #212121;
    border-bottom: 2px solid #212121;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-more-button:hover {
    background-color: #f2f2f2;
}

/* CreatePost */

label {
    display: block;
    font-size: 20px;
    font-weight: 600;
}

.container-textarea-main-title {
    padding-bottom: 10px;
}
.textarea-main-title {
    width: 600px;
}

.container-textarea-sub-title {
    padding-bottom: 10px;
}
.textarea-sub-title {
    width: 600px;
}

.textarea-createpost {
    width: 600px;
    height: 300px;
}
/* CreatePost */

.article-list {
    width: 100%;
}

.article-list-item {
    height: 150px;
    border-right: 1px solid #212121;
    padding: 20px;
    display: flex;
    margin-top: 30px;
    margin-bottom: 40px;
}

.article-list-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px;
}

.article-list-main-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.article-list-date {
    margin-top: auto;
    margin-left: auto;
    font-size: 12px;
}

.date-article {
    margin-top: 10px;
}

.img-article {
    width: 100%;
}

.link-next-article {
    font-weight: bold;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 10px;
}

.link-next-article:hover {
    background-color: #f2f2f2;
}

.closing-article {
    float: right;
}

.contact-full {
    min-height: 100vh;
    margin: 10px;
}
.contact-link {
    font-weight: bold;
}

.contact {
    padding-bottom: 10px;
}

#pic-one {
    max-width: 250px;
}

.single-message {
    border: 2px solid #212121;
    margin: 10px;
}

.cookie-banner {
    background-color: #9fc5e8;
    position: fixed;
    z-index: 20;
    bottom: 0;
    padding-bottom: 20px;
    border-top: 3px solid black;
}
.cookie-text {
    background-color: #9fc5e8;
}

.cookie-options {
    background-color: #9fc5e8;
    display: flex;
    justify-content: space-evenly;
}

.cookie-button:hover {
    background-color: #f2f2f2;
}

@media screen and (max-width: 700px) {
    .header-maintitle {
        font-size: 8vw;
    }
    .header-subtitle {
        font-size: 3vw;
    }

    .article-list-item {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 90px;
    }
    .article-list-img {
        max-width: 70%;
    }
    .article-list-date {
        margin-right: 20px;
    }
}

/* extra test domain */
#username,
#domain {
    font-size: 0;
}

#username span,
#domain span {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
}

#domain .dot {
    margin: 0 2px;
}

#domain .at {
    font-weight: bold;
}

/* view field */

#results-boulder {
    display: flex;
    flex-grow: 1;
}
.boulder-results {
    min-width: 200%;
}
.boulder-result {
    margin-left: 5vw;
}

#boulders-rounds {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
}

.b-num {
    text-decoration: underline;
    font-weight: bold;
}

#total {
    text-decoration: underline;
    font-weight: bold;
    margin-right: 7vw;
}
